import Axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useCallback, useContext, useRef } from 'react';
import { API_URL } from '../config';
import { ModalsContext } from '../context/modal.context';
import { toast } from 'react-toastify';
// import { useAuth0 } from '@auth0/auth0-react';

const STATUS_CODE_401 = 401;

const parseApiError = (error: any) => {
  return (
    error?.response?.data?.error_description ||
    error?.response?.data?.Message ||
    error?.response?.data ||
    'Нешто је пошло по злу'
  );
};

const getHeaders = (config: AxiosRequestConfig) => {
  // const { isAuthenticated } = useAuth0();

  if (!config.headers) {
    return new Error('Missing config.headers');
  }

  // if (isAuthenticated) {
  //   config.headers['Authorization'] =
  // }

  config.headers.Accept = '*/*';
  if (!config.headers['Content-Type']) {
    config.headers['Content-Type'] = 'application/json';
  }
  return config;
};

export const axios = Axios.create({
  baseURL: API_URL,
});

export type ApiResponse<T> = Promise<T>;

export const WithAxios = ({ children }: any) => {
  const { startLoader, endLoader } = useContext(ModalsContext);
  const resInterceptorId = useRef<number | null>(null);
  const reqInterceptorId = useRef<number | null>(null);

  const handleError = (error: any) => {
    const errObj: any = error.toJSON();
    // notification.error({
    //   message: 'Грешка',
    //   description: parseApiError(error),
    // });
    // handleUpdateModals({ infoMessage: parseApiError(error), infoTitle: 'Обавештење:', modalVisible: 'info' });
    toast(parseApiError(error));
    if (errObj?.status === STATUS_CODE_401 && !window.location.href.includes('login')) {
      window.location.href = '/login';
      localStorage.clear();
    }
  };

  const authRequestInterceptor = (config: AxiosRequestConfig) => {
    startLoader();
    return getHeaders(config);
  };

  const getResponseData = (response: { data: string }) => {
    endLoader();
    return response.data || response;
  };

  const handleResponseErrors = (error: AxiosError) => {
    endLoader();
    handleError(error);
    return Promise.reject(error);
  };

  const setInterceptor = useCallback(() => {
    //@ts-ignore
    if (axios.interceptors.request.handlers.length === 1) return;
    resInterceptorId.current = axios.interceptors.response.use(getResponseData as any, handleResponseErrors);
    reqInterceptorId.current = axios.interceptors.request.use(authRequestInterceptor as any);
  }, [resInterceptorId, reqInterceptorId]);

  setInterceptor();

  return children;
};

export const setHeaders = (token: string) => {
  if (token) axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  else axios.defaults.headers.common.Authorization = null;
};
