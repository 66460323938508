import { INavBar } from './host.types';

export const NavBarOptions: INavBar[] = [
  {
    name: 'Parking Info',
    path: 'info',
  },
  {
    name: 'Reports',
    path: 'reports',
  },
];

export const TrafficInfoReport = [
  {
    name: 'Now on parking',
    numberOfCars: '7',
  },
  {
    name: 'Future reservations',
    numberOfCars: '7',
  },
  {
    name: 'Success reservations',
    numberOfCars: '30',
  },
  {
    name: 'Cancelled reservations',
    numberOfCars: '30',
  },
  {
    name: 'Total amount',
    numberOfCars: '1000000 rsd',
  },
];
