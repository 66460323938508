import { omit, trim } from 'lodash';
import dayjs from 'dayjs';
import { parkingRepo } from './parking.repo';
import { ICommentRequest, IRequestParkingPlace, IReservationRequest } from './parking.types';

class ParkingService {
  getParkingPlacesByLocation(location: { latitude: number; longitude: number }) {
    return parkingRepo.getParkingPlacesByLocation(location);
  }
  getParkingPlaces = () => {
    return parkingRepo.getParkingPlaces();
  };

  getParking = (id: string) => {
    return parkingRepo.getParking(id);
  };

  getParkingWithAddress = (values: any) => {
    console.log(values);
    const newValues = {
      ...values,
      start: Math.floor(dayjs(values.start).valueOf() / 1000),
    };
    return parkingRepo.getParkingWithAddress(newValues);
  };

  getComments = (payload: IRequestParkingPlace) => {
    return parkingRepo.getComments(payload);
  };

  postComment = (payload: ICommentRequest) => {
    return parkingRepo.postComment(payload);
  };

  getListOfReservations = () => {
    return parkingRepo.getListOfReservations();
  };

  postReservation = (payload: IReservationRequest) => {
    console.log(payload, 'start');

    const newPayload = {
      ...payload,
      start: dayjs(payload.start).valueOf() / 1000,
    };

    return parkingRepo.postReservation(newPayload);
  };

  payReservation(reservationUuid: any) {
    return parkingRepo.payReservation(reservationUuid);
  }

  deleteReservation = (id: string) => {
    return parkingRepo.deleteReservation(id);
  };

  changeReservation = (payload: any) => {
    const newPayload = {
      ...payload,
      start: dayjs(payload.start).valueOf() / 1000,
    };
    return parkingRepo.changeReservation(newPayload);
  };

  changeTimeFormatter = (time: any) => {
    return time ? time.format('YYYY-MM-DDTHH:mm:ss') : null;
  };

  changeToDayjs = (date: any) => {
    const stringDate = this.changeTimeFormatter(date);
    return dayjs(stringDate);
  };

  convertBase64 = async (file: any) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file.originFileObj);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = error => {
        reject(error);
      };
    });
  };

  formatToString = (date: string) => {
    const dateFirst = date.split(' ');
    const dateSplit = dateFirst[0].split('/');
    const newString = `${dateSplit[2]}-${dateSplit[1]}-${dateSplit[0]}T${dateFirst[1]}`;
    return dayjs(newString);
  };

  changeDayOfWeek = (day: number | string | undefined) => {
    switch (day) {
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      case 7:
        return 'Sunday';

      default:
        break;
    }
  };

  pickFields = (obj: any) => {
    const removedKeys = [];
    for (const key in obj) {
      if (typeof obj[key] === 'string') {
        obj[key] = trim(obj[key]);
      }
      if (obj[key] === null || obj[key] === undefined || obj[key] === '') {
        removedKeys.push(key);
      }
    }
    obj = omit(obj, removedKeys);
    return obj;
  };
}

export const parkingService = new ParkingService();
