import React, { useContext, useState } from 'react';
import style from './WorkingHours.module.scss';
import { ParkingAppContext } from '../../context/parking.context';
import { parkingService } from '../../modules/parking/parking.service';
import { WorkingHoursListItem } from './components/WorkingHoursListItem/WorkingHoursListItem';
import { getWeekday } from '../../modules/parking/parking.constants';
// import { DropDownBtn } from '../../assets/icons/DropDownBtn/DropDownBtn';

enum Weekdays {
  Monday = 'Ponedeljak',
  Tuesday = 'Utorak',
  Wednesday = 'Sreda',
  Thursday = 'Četvrtak',
  Friday = 'Petak',
  Saturday = 'Subota',
  Sunday = 'Nedelja',
}

export const WorkingHours: React.FC = () => {
  const { parkingPlace } = useContext(ParkingAppContext);
  const [isOpen, setIsOpen] = useState(false);

  const weekday = getWeekday();

  const workingHours = parkingPlace.workingHours.map(item => {
    return {
      ...item,
      dayInAWeek: Weekdays[parkingService.changeDayOfWeek(item.dayInAWeek) as keyof typeof Weekdays],
    };
  });

  const isVisible = () => {
    setIsOpen(!isOpen);
  };

  const d = new Date();
  const day = weekday[d.getDay()];
  const indexOfDay = workingHours.findIndex(item => item.dayInAWeek === day);

  return (
    <div className={style.wrapper}>
      {parkingPlace.uuid !== '' && !isOpen ? (
        <div className={style.today} onClick={isVisible}>
          <span>{workingHours[indexOfDay].dayInAWeek}</span>
          <div>
            <span>{workingHours[indexOfDay].openTime}h</span>-<span>{workingHours[indexOfDay].closeTime}h</span>
          </div>
          {/* <div className={style.dropDownBtn}>
            <DropDownBtn />
          </div> */}
        </div>
      ) : (
        <div className={style.dayInAWeek} onClick={isVisible}>
          {workingHours.map((item: any) => {
            return <WorkingHoursListItem item={item} />;
          })}
        </div>
      )}
    </div>
  );
};
