import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import './i18n';
import reportWebVitals from './reportWebVitals';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter } from 'react-router-dom';
import { ParkingAppProvider } from './context/parking.context';
import { ModalsContextProvider } from './context/modal.context';
import { WithAxios } from './utils/axios';

//@ts-ignore

/////////////////////////// vratiti na stranicu na kojoj si bio
const onRedirectCallback = params => {
  console.log(params, 'params');
};

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <BrowserRouter>
    <ParkingAppProvider>
      <ModalsContextProvider>
        <WithAxios>
          <Auth0Provider
            domain="parkpilot-project.eu.auth0.com"
            clientId="5LJtCIlImdc3ICvE9U1ov8xBJmY7GPa2"
            authorizationParams={{
              redirect_uri: window.location.origin,
              audience: 'https://parkpilot.ai',
            }}
            onRedirectCallback={onRedirectCallback}
          >
            <App />
          </Auth0Provider>
        </WithAxios>
      </ModalsContextProvider>
    </ParkingAppProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

reportWebVitals();
