import React from 'react';

import styles from './Reports.module.scss';
import { TrafficInfo } from './components/TrafficInfo/TrafficInfo';
import { TrafficList } from './components/TrafficList/TrafficList';
export const Reports: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <TrafficInfo />
      <TrafficList />
    </div>
  );
};
