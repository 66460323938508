import React from 'react';
import style from './DayPickerNewParking.module.scss';
import { TimePicker, Form } from 'antd';
import { useTranslation } from 'react-i18next';

export const DayPickerNewParking: React.FC = () => {
  const { t } = useTranslation();
  const handleFocus = (e: any) => {
    if (window.innerWidth < 768) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className={style.dayPickerWrapper} id="time-per-day">
      <Form.Item label={t('util.weekdays.monday')} name="start1" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} placeholder={[t('util.start'), t('util.end')]} />
      </Form.Item>
      <Form.Item label={t('util.weekdays.tuesday')} name="start2" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} placeholder={[t('util.start'), t('util.end')]} />
      </Form.Item>
      <Form.Item label={t('util.weekdays.wednesday')} name="start3" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} placeholder={[t('util.start'), t('util.end')]} />
      </Form.Item>
      <Form.Item label={t('util.weekdays.thursday')} name="start4" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} placeholder={[t('util.start'), t('util.end')]} />
      </Form.Item>
      <Form.Item label={t('util.weekdays.friday')} name="start5" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} placeholder={[t('util.start'), t('util.end')]} />
      </Form.Item>
      <Form.Item label={t('util.weekdays.saturday')} name="start6" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} placeholder={[t('util.start'), t('util.end')]} />
      </Form.Item>
      <Form.Item label={t('util.weekdays.sunday')} name="start7" className={style.day} rules={[{ required: true }]}>
        <TimePicker.RangePicker format="HH a" onFocus={handleFocus} placeholder={[t('util.start'), t('util.end')]} />
      </Form.Item>
    </div>
  );
};
