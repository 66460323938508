import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useState } from 'react';
import style from './AddComment.module.scss';
import { Button } from '../../../../../../../../../../../components/Button/Button';
import { Form, Input } from 'antd';
import { ICommentRequest } from '../../../../../../../../../../../modules/parking/parking.types';
import { useDebouncedCallback } from 'use-debounce';
import { parkingService } from '../../../../../../../../../../../modules/parking/parking.service';
import to from 'await-to-js';
import { ParkingAppContext } from '../../../../../../../../../../../context/parking.context';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

const INITIAL_COMMENT = {
  body: '',
};

export const AddComment: React.FC = () => {
  const { t } = useTranslation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [commentOpen, setCommentOpen] = useState(false);
  const [form] = Form.useForm();
  const { setParkingAppStateHandler, parkingPlace, commentList } = useContext(ParkingAppContext);

  const toggleComment = () => {
    if (!isAuthenticated) {
      return loginWithRedirect();
    }
    setCommentOpen(!commentOpen);
    form.resetFields();
  };

  const onSubmit = useDebouncedCallback(async (values: Partial<ICommentRequest>) => {
    const payload = { ...values, guestId: '4d9dff7e-46c4-47fb-a980-33c35540f2bd', parkingId: parkingPlace.uuid };
    const [err, res] = await to(parkingService.postComment(payload));
    if (err || !res) return toast.error(t('system.notifications.errors.generic'));
    toast.success(t('system.notifications.success.addedComment'));

    const newCommentList = commentList;
    newCommentList.push(res);

    setParkingAppStateHandler({ commentList: newCommentList });
    setCommentOpen(false);
    form.resetFields();
  }, 400);

  return (
    <div>
      {!commentOpen && (
        <div className={style.addComment}>
          <Button name="open-add-comment" onClick={toggleComment}>
            {t('parking.addComment')}
          </Button>
        </div>
      )}
      {commentOpen && (
        <Form
          className={style.form}
          form={form}
          layout="vertical"
          name="comment-text-area"
          initialValues={INITIAL_COMMENT}
          onFinish={(values: ICommentRequest) => onSubmit(values)}
        >
          <Form.Item name="body" label={t('parking.addComment')}>
            <Input.TextArea name="comment" />
          </Form.Item>
          <div className={style.buttons}>
            <Button name="close-add-comment" className="ant-btn-dangerous" onClick={toggleComment}>
              {t('parking.cancel')}
            </Button>
            <Button name="add-comment" htmlType="submit" className={style.submit}>
              {t('parking.addComment')}
            </Button>
          </div>
        </Form>
      )}
    </div>
  );
};
