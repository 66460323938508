import { useTranslation } from 'react-i18next';

export const getDurationSelectOptions = () => {
  const { t } = useTranslation();
  return [
    {
      value: t('util.termOptions.short.value'),
      label: t('util.termOptions.short.label'),
    },
    {
      value: t('util.termOptions.mid.value'),
      label: t('util.termOptions.mid.label'),
    },
    {
      value: t('util.termOptions.long.value'),
      label: t('util.termOptions.long.label'),
    },
  ];
};

export const getWeekday = () => {
  const { t } = useTranslation();
  return [
    t('util.weekdays.sunday'),
    t('util.weekdays.monday'),
    t('util.weekdays.tuesday'),
    t('util.weekdays.wednesday'),
    t('util.weekdays.thursday'),
    t('util.weekdays.friday'),
    t('util.weekdays.saturday'),
  ];
};
