import React from 'react';
import style from './Comment.module.scss';

import { AddComment } from './components/Add Comment/AddComment';
import { CommentList } from './components/CommentList/CommentList';

export const Comment: React.FC = () => {
  return (
    <div className={style.scrollReview}>
      <AddComment />
      <CommentList />
    </div>
  );
};
