import React from 'react';
import { Button } from 'antd';
import { CloseSquareOutlined } from '@ant-design/icons';

interface ICloseProps {
  onClick: () => void;
}

export const CloseButton: React.FC<ICloseProps> = props => {
  return (
    <Button
      style={{
        justifyContent: 'center',
        width: '0px',
        display: 'flex',
      }}
      onClick={() => props.onClick()}
    >
      <CloseSquareOutlined style={{ fontSize: 20 }} />
    </Button>
  );
};
