import React, { useContext, useEffect } from 'react';
import { ParkingAppContext } from '../../../../../context/parking.context';

import axios from 'axios';
import { API_URL } from '../../../../../config';

import { ReservationItem } from './ReservationItem/ReservationItem';

import './Reservations.css';
import { Header } from '../Header/Header';
import { QrCodeModal } from '../../../../../components/Modals/QrCodeModal/QrCodeModal';
import { useAuth0 } from '@auth0/auth0-react';
import { useTranslation } from 'react-i18next';
import { ToastContainer } from 'react-toastify';

export const Reservations: React.FC = () => {
  const { t } = useTranslation();
  const { setParkingAppStateHandler, reservationList, token } = useContext(ParkingAppContext);
  const { isAuthenticated, user } = useAuth0();
  console.log(reservationList);
  const instance = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });
  useEffect(() => {
    const fetchData = async () => {
      if (token) {
        try {
          const res = await instance.get(`/users/reservations`);
          setParkingAppStateHandler({ reservationList: res.data });
          console.log(reservationList);
          console.log(res.data);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      }
    };

    fetchData();
  }, [token]);
  return (
    <>
      {isAuthenticated && user !== null ? (
        <>
          <Header />
          <div className="reservationsBox">
            <h1>{t('reservationsPageTitle')}</h1>
            {reservationList
              .sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime())
              .reverse()
              .map(r => (
                <ReservationItem key={r.reservationUuid} {...r} />
              ))}
            <QrCodeModal />
            <ToastContainer />
          </div>
        </>
      ) : (
        window.location.replace('/')
      )}
    </>
  );
};
