import { Modal } from '../../Modal/Modal';
import { useContext } from 'react';
import { ModalsContext } from '../../../context/modal.context';
import { useTranslation } from 'react-i18next';

export const QrCodeModal: React.FC = () => {
  const { t } = useTranslation();
  const { isModalVisible, modals, clearModals } = useContext(ModalsContext);
  return (
    <Modal
      name="qr-code-modal"
      // closable={false}
      open={isModalVisible('qr-code-modal')}
      className={`.and-modal-wrap .ant-modal`}
      style={{ padding: '20px', display: 'flex', fontWeight: 'bold', justifyContent: 'center' }}
      okButtonProps={{ style: { display: 'none' } }}
      cancelButtonProps={{ style: { display: 'none' } }}
      onCancel={clearModals}
      title={t('reservation.qrCode')}
    >
      {modals.modalData}
    </Modal>
  );
};
