import React from 'react';
import { Table } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import styles from './TrafficList.module.scss';

interface DataType {
  key: React.Key;
  name: string;
  gender: string;
  email: string;
  date: string;
  amount: string;
  hours: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: '',
    dataIndex: 'key',
    width: 50,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    width: 150,
    responsive: ['md'],
  },
  {
    title: 'Gender',
    dataIndex: 'gender',
    width: 150,
    responsive: ['md'],
  },
  {
    title: 'Email',
    dataIndex: 'email',
    responsive: ['md'],
  },
  {
    title: 'Date',
    dataIndex: 'date',
    responsive: ['md'],
  },
  {
    title: 'Hours',
    dataIndex: 'hours',
    responsive: ['md'],
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    responsive: ['md'],
  },
];

const data: DataType[] = [];
for (let i = 0; i < 100; i++) {
  data.push({
    key: i,
    name: `Edward King ${i}`,
    gender: 'male',
    email: 'edward@gmail.com',
    date: '20-01-2022',
    amount: '150 rsd',
    hours: '17:00 h',
  });
}

export const TrafficList: React.FC = () => (
  <div className={styles.table}>
    <div className={styles.name}>Traffic List:</div>
    <Table
      columns={columns}
      dataSource={data}
      pagination={{
        pageSize: 20,
        showSizeChanger: false,
        hideOnSinglePage: true,
        position: ['bottomCenter'],
      }}
      scroll={{ y: 400 }}
    />
  </div>
);
