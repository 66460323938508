import { useState, useRef, createContext } from 'react';

interface ModalsContextState {
  modalVisible: string;
  isLoading: boolean;
  infoTitle: string;
  infoMessage: string;
  modalType?: string;
  modalData?: React.ReactNode;
}

export type UpdateModalStateFunction = (updatedState: Partial<ModalsContextState>) => void;

export interface ModalContextProviderProps {
  children: JSX.Element;
}

const initialState = (): ModalsContextState => ({
  modalVisible: '',
  infoTitle: '',
  infoMessage: '',
  isLoading: false,
  modalType: '',
  modalData: null,
});

export const ModalsContext = createContext<{
  modals: ModalsContextState;
  handleUpdateModals: UpdateModalStateFunction;
  isModalVisible: any;
  clearModals: any;
  loaderRef: any;
  startLoader: any;
  endLoader: any;
}>(null as any);

export const ModalsContextProvider = ({ children }: ModalContextProviderProps) => {
  const [modals, setModals] = useState(initialState());
  const loaderRef = useRef(null);

  const isModalVisible = (modalName: string) => modals.modalVisible === modalName;

  const clearModals = () => {
    setModals(initialState());
  };

  const handleUpdateModals = (updatedState: Partial<ModalsContextState>) => {
    setModals({ ...modals, ...updatedState });
  };

  const startLoader = () => {
    if (loaderRef.current) {
      // handleUpdateModals({ isLoading: true });
      //@ts-ignore
      loaderRef.current.continuousStart();
    }
  };

  const endLoader = () => {
    if (loaderRef.current) {
      // handleUpdateModals({ isLoading: false });
      //@ts-ignore
      loaderRef.current.complete();
    }
  };

  return (
    <ModalsContext.Provider
      value={{ startLoader, endLoader, modals, handleUpdateModals, isModalVisible, clearModals, loaderRef }}
    >
      {children}
    </ModalsContext.Provider>
  );
};
