import React from 'react';

import { Input as ANTInput, InputProps } from 'antd';

import './Input.scss';

interface IInputProps extends InputProps {
  name: string;
}

export const Input: React.FC<IInputProps> = props => {
  return <ANTInput {...props} data-test={`input-${props.name}`} />;
};
