import { ApiResponse, axios } from '../../utils/axios';
import { IAddDevice, IDeviceCommander, IParkingPlaces, IHostData } from '../parking/parking.types';
import queryString from 'query-string';

const ROUTES = {
  PARKING: 'parkings/',
  CREATE: 'create',
  USERS: 'users/',
  DEVICES: 'devices',
  COMMANDER: '/commander',
};

const postNewParking = (payload: any): ApiResponse<IParkingPlaces> => {
  const query = queryString.stringify(payload.data);
  return axios.post(`${ROUTES.PARKING}${ROUTES.CREATE}?${query}`, payload.profileImage, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getHostParkings = (): ApiResponse<IParkingPlaces[]> => {
  return axios.get(`${ROUTES.USERS}${ROUTES.PARKING}`);
};

const deleteParking = (id: string) => {
  return axios.delete(`${ROUTES.PARKING}${id}`);
};

const patchParking = (payload: any) => {
  const query = queryString.stringify(payload);
  return axios.patch(`${ROUTES.PARKING}?${query}`);
};

// User Data
const postHostData = (payload: any): ApiResponse<IHostData> => {
  //const query = queryString.stringify(payload);
  return axios.post(`http://localhost:8082/api/rest/v1/users`, payload, {
    //return axios.post(`${ROUTES.USERS}`, payload, {
    //return axios.post(`users`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getHostData = (id: string): ApiResponse<IHostData> => {
  //return axios.get(`${ROUTES.USERS}${id}`);
  return axios.get(`http://localhost:8082/api/rest/v1/users/${id}`);
};

const postDevice = (payload: any): ApiResponse<IAddDevice> => {
  return axios.post(`${ROUTES.DEVICES}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postDeviceCommander = (payload: any): ApiResponse<IDeviceCommander> => {
  return axios.post(`${ROUTES.DEVICES}${ROUTES.COMMANDER}`, payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const hostRepo = {
  postNewParking,
  getHostParkings,
  deleteParking,
  patchParking,
  postHostData,
  getHostData,
  postDevice,
  postDeviceCommander,
};
