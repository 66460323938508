import React from 'react';
import { Form } from 'antd';
import { Input } from '../Input/Input';

interface RuleConfig {
  required: boolean;
  message?: string;
}

interface FormInputProps {
  name: string;
  inputName: string;
  placeholder: string;
  rules?: RuleConfig[];
  iconComponent?: JSX.Element;
  type: string;
  label?: string;
  disabled?: boolean;
  colon?: boolean;
  step?: string;
}

export const FormInput: React.FC<FormInputProps> = ({
  name,
  placeholder,
  rules,
  iconComponent,
  type,
  label,
  disabled,
  colon,
  inputName,
  step,
}) => {
  return (
    <Form.Item name={name} rules={rules} label={label} colon={colon}>
      <Input
        step={step}
        type={type}
        prefix={iconComponent}
        placeholder={placeholder}
        disabled={disabled}
        name={inputName}
      />
    </Form.Item>
  );
};
