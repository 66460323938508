import React from 'react';
import styles from './StarRating.module.scss'; // Import the SCSS file for styling

interface StarRatingProps {
  averageStar: number; // Average star rating (0 to 5)
}

const StarRating: React.FC<StarRatingProps> = ({ averageStar }) => {
  // Function to render stars
  const renderStars = () => {
    const stars = [];
    const fullStars = Math.floor(averageStar); // Get the number of full stars
    const hasHalfStar = averageStar % 1 >= 0.5; // Check if there's a half star

    for (let i = 0; i < 5; i++) {
      if (i < fullStars) {
        stars.push(
          <span key={i} className={`${styles.star} ${styles.filled}`}>
            ★
          </span>
        );
      } else if (i === fullStars && hasHalfStar) {
        stars.push(
          <span key={i} className={`${styles.star} ${styles.half}`}>
            ★
          </span>
        );
      } else {
        stars.push(
          <span key={i} className={styles.star}>
            ☆
          </span>
        );
      }
    }

    stars.push(
      <span key="avg" className={styles['average-star']}>
        - {averageStar.toFixed(1)} {/* Display average rating with one decimal */}
      </span>
    );

    return stars;
  };

  return <div className={styles['star-rating']}>{renderStars()}</div>;
};

export default StarRating;
