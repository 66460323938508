import { useContext, useState } from 'react';
import { ModalsContext } from '../../../../../context/modal.context';
import { Form } from 'antd';
import { Button } from '../../../../Button/Button';
import { parkingService } from '../../../../../modules/parking/parking.service';
import { IReservationRequest } from '../../../../../modules/parking/parking.types';
import to from 'await-to-js';
import { ParkingAppContext } from '../../../../../context/parking.context';
import style from './ReservationDeleteChangeBtn.module.scss';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { LoadingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
//import { isEmpty } from 'lodash';
interface IPropsPayload {
  payload: IReservationRequest;
}

export const ReservationDeleteChangeBtn: React.FC<IPropsPayload> = payload => {
  const { t } = useTranslation();
  const { modals, clearModals } = useContext(ModalsContext);
  const { reservation, setParkingAppStateHandler, reservationList, card_numbers } = useContext(ParkingAppContext);
  const [isBooking, setIsBooking] = useState(false);

  const makeReservation = async () => {
    setIsBooking(true);
    const [err, res] = await to(parkingService.postReservation(payload.payload));
    if (err || !res) {
      setIsBooking(false);
      return toast.error(
        `${t('system.notifications.errors.reservationFailed')}: ${
          err instanceof AxiosError && err.response ? err.response.data.error : t('system.notifications.errors.generic')
        }`
      );
    }

    const [errPay, resPay] = await to(parkingService.payReservation(res.reservationUuid));
    if (errPay || !resPay) {
      setIsBooking(false);
      await to(parkingService.deleteReservation(res.reservationUuid));
      return toast.error(
        `${t('system.notifications.errors.paymentFailed')}: ${
          errPay instanceof AxiosError && errPay.response
            ? errPay.response.data.error
            : t('system.notifications.errors.generic')
        }`
      );
    }
    toast.success(t('system.notifications.success.reserationBooked'));
    clearModals();
    setIsBooking(false);
  };

  const deleteReservation = async () => {
    const [err, res] = await to(parkingService.deleteReservation(reservation.reservationUuid));
    if (err || !res) return toast.error(t('system.notifications.errors.generic'));
    toast.success(t('system.notifications.success.reservationDeleted'));
    setParkingAppStateHandler({
      reservationList: reservationList.filter(item => {
        return item.reservationUuid !== reservation.reservationUuid;
      }),
    });
    clearModals();
  };

  const changeReservation = async () => {
    const [err, res] = await to(
      parkingService.changeReservation({
        ...payload.payload,
        uuid: reservation.reservationUuid,
        parkingId: reservation.parkingResponse.uuid,
      })
    );
    if (err || !res) return toast.error(t('system.notifications.errors.generic'));
    toast.success(t('system.notifications.success.reservationChanged'));
    const findItemChangeValue = reservationList.findIndex(item => item.reservationUuid === reservation.reservationUuid);
    reservationList[findItemChangeValue] = res;
    clearModals();
  };

  const onSubmit = async (type: string) => {
    switch (type) {
      case 'submit':
        makeReservation();
        return;
      case 'delete':
        deleteReservation();
        return;
      case 'change':
        changeReservation();
        return;

      default:
        break;
    }
  };

  return (
    <div>
      {modals.modalType === 'reservation' ? (
        <Form.Item name="reservation-button" className={style.confirmBtn}>
          <Button
            htmlType="submit"
            onClick={() => onSubmit('submit')}
            name="reservation-modal-button"
            style={{ width: '100%' }}
            disabled={card_numbers.length === 0 || isBooking}
          >
            {isBooking ? <LoadingOutlined /> : t('reservation.submit')}
          </Button>
        </Form.Item>
      ) : (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Form.Item name="delete-button" className={style.deleteBtn}>
            <Button
              className="ant-btn-dangerous"
              onClick={() => onSubmit('delete')}
              htmlType="button"
              name="reservation-delete-button"
              style={{ width: '100%' }}
            >
              {t('reservation.delete')}
            </Button>
          </Form.Item>
          <Form.Item className={style.confirmBtn} name="change-button" style={{ width: '50%', marginLeft: '5px' }}>
            <Button
              htmlType="button"
              onClick={() => onSubmit('change')}
              name="reservation-change-button"
              style={{ width: '100%' }}
            >
              {t('reservation.change')}
            </Button>
          </Form.Item>
        </div>
      )}
    </div>
  );
};
