import visa from '../assets/icons/CardBrands/visa.svg';
import amex from '../assets/icons/CardBrands/amex.svg';
import mastercard from '../assets/icons/CardBrands/mastercard.svg';
import diners from '../assets/icons/CardBrands/diners.svg';
import discover from '../assets/icons/CardBrands/discover.svg';

const cardBrand = {
  VISA: visa,
  MASTERCARD: mastercard,
  AMERICAN_EXPRESS: amex,
  DISCOVER: discover,
  DINERS_CLUB: diners,
} as const;

export default cardBrand;
