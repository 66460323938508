import { Button, Form } from 'antd';
import style from '../AddressForm/form.module.scss';
import { Autocomplete } from '@react-google-maps/api';
import React, { useContext, useState } from 'react';
import { ParkingAppContext } from '../../../../../../../context/parking.context';
import to from 'await-to-js';
import { parkingService } from '../../../../../../../modules/parking/parking.service';
import { IFormAddressToGo } from '../../../../../../../modules/parking/parking.types';
import { DateTimeDurationForm } from '../../../../../../../components/DateTimeDurationForm/DateTimeDurationForm';
import { Input } from '../../../../../../../components/Input/Input';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from 'react-i18next';

const INITIAL_PLACE_TO_GO = {
  address: '',
  start: null,
  duration: 1,
};

export const AddressForm: React.FC = () => {
  const [addressToGo, setAddressToGo] = useState<IFormAddressToGo>(INITIAL_PLACE_TO_GO);
  const { t } = useTranslation();

  const { setParkingAppStateHandler } = useContext(ParkingAppContext);

  const onSubmit = async () => {
    const dayjsDate = parkingService.changeToDayjs(addressToGo.start);

    const [err, res] = await to<any>(parkingService.getParkingWithAddress(addressToGo));
    if (err) return toast.error(`${t('system.notifications.errors.generic')}: ${err.message}`);
    setParkingAppStateHandler({
      parkingList: res,
      timeDateDuration: {
        start: addressToGo.start === null || undefined ? null : dayjsDate,
        duration: addressToGo.duration,
      },
    });
  };

  const changeState = (e: any, type: string) => {
    setAddressToGo({ ...addressToGo, [type]: e.target.value });
  };

  return (
    <Form
      labelCol={{ span: 20 }}
      wrapperCol={{ span: 25 }}
      layout="vertical"
      className={style.form}
      name="parking-search"
    >
      <div className={style.mobileForm}>
        <div className={style.destinationAndDuration}>
          <Autocomplete>
            <div>
              <label>{t('search.address')}:</label>
              <Input
                name="address"
                placeholder={t('search.addressPlaceholder')}
                onBlur={e => changeState(e, 'address')}
                onChange={e => changeState(e, 'address')}
                value={addressToGo.address}
                required={true}
              />
            </div>
          </Autocomplete>
        </div>
        <div className={`${style.dateAndTime}`}>
          <DateTimeDurationForm setState={setAddressToGo} state={addressToGo} />
        </div>
      </div>
      <Form.Item className={`${style.button}`}>
        <Button htmlType="submit" onClick={onSubmit}>
          {t('search.searchButton')}
        </Button>
      </Form.Item>
    </Form>
  );
};
