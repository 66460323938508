import defaultImage from '../../assets/img/parking.jpeg';
import styles from './ImageComponent.module.scss';
interface ImageProps {
  imageUrl: string;
}

const ImageComponent: React.FC<ImageProps> = props => {
  const isValidImageUrl = props.imageUrl && props.imageUrl.trim() !== 'data:image/png;base64,null';
  return (
    <div className={styles.imageWrapper}>
      {isValidImageUrl ? (
        <img src={props.imageUrl} alt="Image from backend" />
      ) : (
        <img src={defaultImage} alt="Default image" />
      )}
    </div>
  );
};

export default ImageComponent;
