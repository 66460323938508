// import { parkingService } from '../parking/parking.service';
// import { IRequestParkingPlace } from '../parking/parking.types';
import dayjs from 'dayjs';
import { parkingService } from '../parking/parking.service';
import { IParkingPlaces, IWorkingHours } from '../parking/parking.types';
import { hostRepo } from './host.repo';

enum Weekdays {
  Monday = 'Ponedeljak',
  Tuesday = 'Utorak',
  Wednesday = 'Sreda',
  Thursday = 'Četvrtak',
  Friday = 'Petak',
  Saturday = 'Subota',
  Sunday = 'Nedelja',
}

class HostService {
  postNewParking = (payload: any) => {
    const newPayload = {
      data: {
        id: payload.id,
        name: payload.name,
        address: payload.address,
        basePrice: payload.basePrice,
        start1: +this.changeTimeRange(payload.start1[0]),
        end: +this.changeTimeRange(payload.start1[1]),
        start2: +this.changeTimeRange(payload.start2[0]),
        end2: +this.changeTimeRange(payload.start2[1]),
        start3: +this.changeTimeRange(payload.start3[0]),
        end3: +this.changeTimeRange(payload.start3[1]),
        start4: +this.changeTimeRange(payload.start4[0]),
        end4: +this.changeTimeRange(payload.start4[1]),
        start5: +this.changeTimeRange(payload.start5[0]),
        end5: +this.changeTimeRange(payload.start5[1]),
        start6: +this.changeTimeRange(payload.start6[0]),
        end6: +this.changeTimeRange(payload.start6[1]),
        start7: +this.changeTimeRange(payload.start7[0]),
        end7: +this.changeTimeRange(payload.start7[1]),
      },
      profileImage: payload.profileImage,
    };
    return hostRepo.postNewParking(newPayload);
  };

  getHostParkings = () => {
    return hostRepo.getHostParkings();
  };

  deleteParking = (id: string) => {
    return hostRepo.deleteParking(id);
  };
  patchParking = (payload: any) => {
    const newPayload = this.makeDataToSend(payload);
    return hostRepo.patchParking(newPayload);
  };

  changeTimeRange = (time: any) => {
    return time ? time.format('HH') : null;
  };

  newHostParkingList = (payload: IParkingPlaces[]) => {
    const newArray = payload.map((item: IParkingPlaces) => {
      return {
        ...item,
        workingHours: this.changeWorkingHours(item.workingHours),
      };
    });

    return newArray;
  };

  changeWorkingHours = (workingHours: IWorkingHours[]) => {
    const changeDays = workingHours.map((item: IWorkingHours) => {
      return {
        ...item,
        dayInAWeek: Weekdays[parkingService.changeDayOfWeek(item.dayInAWeek) as keyof typeof Weekdays],
      };
    });
    return changeDays;
  };

  formatTimeRanges = (timeRanges: IWorkingHours[]) => {
    const durations: { [key: string]: any[] } = {};

    timeRanges.forEach(({ openTime, closeTime }, index: number) => {
      const openDateTime = dayjs().hour(openTime).minute(0).second(0);
      const closeDateTime = dayjs().hour(closeTime).minute(0).second(0);

      const key = `start${index + 1}`;
      durations[key] = [openDateTime, closeDateTime];
    });

    return durations;
  };

  postHostData = (payload: any) => {
    const newPayload = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      bankAccount: payload.bankAccount,
    };
    return hostRepo.postHostData(newPayload);
  };

  getHostData = (id: string) => {
    return hostRepo.getHostData(id);
  };

  postDevice = (payload: any) => {
    const newPayload = {
      parkingId: payload.uuid,
      securityKey: payload.securityKey,
      authKey: payload.authKey,
      ip: payload.ip,
    };
    return hostRepo.postDevice(newPayload);
  };

  postDeviceCommander = (payload: any) => {
    const newPayload = {
      command: payload.command,
      parkingId: payload.parkingId,
    };
    return hostRepo.postDeviceCommander(newPayload);
  };

  makeDataToSend = (payload: any) => {
    return {
      uuid: payload.id,
      name: payload.name,
      address: payload.address,
      basePrice: payload.basePrice,
      start1: +this.changeTimeRange(payload.start1[0]),
      end: +this.changeTimeRange(payload.start1[1]),
      start2: +this.changeTimeRange(payload.start2[0]),
      end2: +this.changeTimeRange(payload.start2[1]),
      start3: +this.changeTimeRange(payload.start3[0]),
      end3: +this.changeTimeRange(payload.start3[1]),
      start4: +this.changeTimeRange(payload.start4[0]),
      end4: +this.changeTimeRange(payload.start4[1]),
      start5: +this.changeTimeRange(payload.start5[0]),
      end5: +this.changeTimeRange(payload.start5[1]),
      start6: +this.changeTimeRange(payload.start6[0]),
      end6: +this.changeTimeRange(payload.start6[1]),
      start7: +this.changeTimeRange(payload.start7[0]),
      end7: +this.changeTimeRange(payload.start7[1]),
    };
  };
}

export const hostService = new HostService();
