import React from 'react';
import { TrafficInfoReport } from '../../../../../../modules/host/host.constants';
import styles from './TrafficInfo.module.scss';
import { Select } from '../../../../../../components/Select/Select';

export const TrafficInfo: React.FC = () => {
  const ParkingOptions = [
    {
      label: 'Vojislaca Ilica',
      value: 'vojislavaIlica',
      id: '0',
    },
    {
      label: 'Milana Rakica',
      value: 'milanaRakica',
      id: '1',
    },
  ];
  return (
    <div className={styles.wrapper}>
      <div className={styles.parkingPlace}>
        <label>Parking place: </label>
        <Select name="parking-place" options={ParkingOptions} defaultValue={ParkingOptions[0]} />
      </div>
      <div className={styles.listInfo}>
        {TrafficInfoReport.map((item, index) => {
          return (
            <div className={styles.boxwrapper} key={index}>
              <div className={styles.box}>
                <div className={styles.name}>{item.name}</div>
                <div className={styles.number}>{item.numberOfCars}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
