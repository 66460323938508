import { GoogleMap, Marker } from '@react-google-maps/api';
import { INITIAL_PARKING_PLACE, ParkingAppContext } from '../../../../../../../context/parking.context';
import { useContext, useEffect, useState } from 'react';
import style from './Map.module.scss';
import { InfoParkingModal } from '../InfoParkingModal/InfoParkingModal';
import { ModalsContext } from '../../../../../../../context/modal.context';
import { IParkingPlaces } from '../../../../../../../modules/parking/parking.types';
import { parkingService } from '../../../../../../../modules/parking/parking.service';
import to from 'await-to-js';
import { useDebouncedCallback } from 'use-debounce';

const Map = () => {
  const { handleUpdateModals } = useContext(ModalsContext);
  const { isInfoParkingVisible, parkingList, parkingPlace, setParkingAppStateHandler } = useContext(ParkingAppContext);

  // State to store user's location
  const [userLocation, setUserLocation] = useState<{ lat: number; lng: number } | null>(null);

  // Default center point (fallback)
  const center = {
    lat: parkingPlace.latitude || userLocation?.lat || 44.8125,
    lng: parkingPlace.longitude || userLocation?.lng || 20.4612,
  };

  const handleUserLocation = useDebouncedCallback(async (position: GeolocationPosition) => {
    const { latitude, longitude } = position.coords;
    setUserLocation({ lat: latitude, lng: longitude });

    const [err, res] = await to(parkingService.getParkingPlacesByLocation({ latitude, longitude }));
    if (err || !res) return;

    setParkingAppStateHandler({ parkingList: res, parkingPlace: res[0] ? res[0] : INITIAL_PARKING_PLACE });
  }, 400);

  const handleLocationError = (error: GeolocationPositionError) => {
    console.error("Error fetching user's location: ", error);
  };

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(handleUserLocation, handleLocationError);
    }
  }, []);

  const openParkingModal = (place: any) => {
    handleUpdateModals({ modalVisible: 'InfoParkingModal' });
    setParkingAppStateHandler({ parkingPlace: place, isInfoParkingVisible: true });
  };

  return (
    <div className={`${style.container}`}>
      {isInfoParkingVisible && <InfoParkingModal />}

      <GoogleMap
        center={center}
        zoom={13}
        mapContainerStyle={{ width: '100%', borderRadius: '20px' }}
        options={{ zoomControl: true, streetViewControl: false, mapTypeControl: false, fullscreenControl: false }}
      >
        {/* Render parking places */}
        {parkingList &&
          parkingList.map((place: IParkingPlaces, index: number) => (
            <Marker
              key={index}
              onClick={() => openParkingModal(place)}
              position={{ lat: place.latitude as number, lng: place.longitude as number }}
            />
          ))}

        {/* Render user location marker if available */}
        {userLocation && (
          <Marker
            position={userLocation}
            icon={{
              url: 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png',
            }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default Map;
