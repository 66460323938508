import React, { useState, useRef, useEffect } from 'react';
import './ProfileItem.css';

// Extend ProfileItemProps to include onclick
interface ProfileItemProps {
  title: string;
  body: React.ReactNode;
  onclick?: () => void; // Optional onclick prop
}

export const ProfileItem: React.FC<ProfileItemProps> = ({ title, body, onclick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const profileItemRef = useRef<HTMLDivElement>(null); // Reference to the whole profile item

  const toggleOpen = () => {
    setIsOpen(prev => !prev); // Toggle open state
    if (onclick) {
      onclick(); // Call the onclick function if provided
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    // Check if the click is outside the profile item
    if (profileItemRef.current && !profileItemRef.current.contains(event.target as Node)) {
      setIsOpen(false); // Close the profile item
    }
  };

  useEffect(() => {
    // Add event listener on mount
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup on unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []); // Empty dependency array means it runs once on mount

  return (
    <div className="profileItem" ref={profileItemRef}>
      <div className="profileItemHeader" onClick={toggleOpen}>
        <h3>{title}</h3>
      </div>
      <div
        className={`profileItemBody ${isOpen ? 'open' : ''}`} // Toggle class based on state
        style={{
          maxHeight: isOpen ? '1000px' : '0px', // Use a large maxHeight for smooth transition
          transition: 'max-height 0.5s ease-out, opacity 0.5s ease-out',
          overflow: 'hidden',
        }}
      >
        {body} {/* Body content passed as a prop */}
      </div>
    </div>
  );
};
