import React, { useContext, useEffect } from 'react';
import style from './InfoParkingModal.module.scss';
import { ModalsContext } from '../../../../../../../context/modal.context';
import { Button } from '../../../../../../../components/Button/Button';
import { useAuth0 } from '@auth0/auth0-react';
import ParkingInfo from './components/ParkingInfo/ParkingInfo';
import { Footer } from 'antd/es/layout/layout';
import { Comment } from './components/Comment/Comment';
import { ParkingAppContext } from '../../../../../../../context/parking.context';
import { CloseButton } from '../../../../../../../components/CloseButton/CloseButton';
import { parkingService } from '../../../../../../../modules/parking/parking.service';
import to from 'await-to-js';
import axios from 'axios';
import { isEmpty } from 'lodash';
//import { useNavigate } from 'react-router';
import ImageComponent from '../../../../../../../components/ImageComponent/ImageComponent';
import { API_URL } from '../../../../../../../config';
import { useTranslation } from 'react-i18next';
/*
interface IResponseCard {
  user_cards: string[];
}
*/

export const InfoParkingModal: React.FC = () => {
  const { t } = useTranslation();
  const { handleUpdateModals } = useContext(ModalsContext);
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const { setParkingAppStateHandler, parkingPlace, token, card_numbers } = useContext(ParkingAppContext);
  //const navigate = useNavigate();
  //moj dodatak

  const instance = axios.create({
    baseURL: API_URL,
    headers: { Authorization: `Bearer ${token}` },
  });

  const fetchCard = async () => {
    try {
      //const response = await axios.get<IResponseCard>(`https://parkpilot.ai/api/payment/user?token=${token}`);
      const response = await instance.get(`/payspot/payment-instruments?skip=0&limit=10`);
      if (!isEmpty(response.data)) {
        return setParkingAppStateHandler({ card_numbers: response.data }); // data.user_cards
      }
      //navigate('/payment');
      //navigate(response.data.link);
      console.log(response.data[0]);
      console.log(card_numbers);
    } catch (err) {
      throw new Error(`Error fetching data`);
    }
  };

  const openReservationModal = async () => {
    if (!isAuthenticated) {
      return loginWithRedirect();
    }
    fetchCard();
    handleUpdateModals({ modalVisible: 'reservation-parking-modal', modalType: 'reservation' });
  };

  const getComment = async () => {
    const [err, res] = await to(parkingService.getComments({ id: parkingPlace.uuid }));
    if (err || !res) return;
    setParkingAppStateHandler({ commentList: res });
  };

  useEffect(() => {
    getComment();
  }, [parkingPlace]);

  const closeInfoParkingModal = () => {
    setParkingAppStateHandler({ isInfoParkingVisible: false });
  };

  return (
    <>
      <div className={style.wrapperInfo}>
        <div className={style.infoParking}>
          <div className={style.closeButton}>
            <CloseButton onClick={closeInfoParkingModal} />
          </div>
          <div className={style.img}>
            <ImageComponent imageUrl={`data:image/png;base64,${parkingPlace.image}`} />
          </div>
          <ParkingInfo />
          <Comment />
          <Footer className={style.footer}>
            <Button name="reservation" onClick={openReservationModal}>
              {t('parking.book')}
            </Button>
          </Footer>
        </div>
      </div>
    </>
  );
};
